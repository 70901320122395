<template>
  <b-container fluid class="bg-login full-height">
    <b-row class="full-height">
      <b-col cols="12" md="6" class="left-side" :class="{'heaven': isHeaven}"> </b-col>
      <b-col cols="12" md="6" class="right-side">
        <div class="inner-wrapper" data-anima="right" v-if="!loading">
          <div class="d-flex justify-content-between">
            <span class="logo mb-5">
              <img v-if="!isHeaven" src="@/assets/logo.png" />
              <img v-else src="@/assets/img/icons-heaven/logo.svg" />
            </span>
            <div>
              <Locales :positionAbsolute="false"/>
            </div>
          </div>

          <div class="register-title">
            <h5>
              {{ $t("views.seller.login.text_1578") }} <span>Greenn</span>
              <br>
              {{ $t("views.seller.login.text_1579") }}
            </h5>
            <p> {{ $t("views.seller.login.text_1580") }}</p>
          </div>

          <b-alert variant="success" :show="showAlertCashback" class="b-alert-cashback">
            <p class="title-alert">Cashback!</p> 
            <span class="text-alert">{{ $t('views.seller.login.text_1577') }}</span>
          </b-alert>
          <b-form @submit.prevent="onSubmit" novalidate>
            <b-form-group :label="$t('views.seller.login.text_1556')" label-for="email">
              <b-form-input
                id="email"
                :placeholder="$t('views.seller.login.text_1557')"
                v-model="email"
                type="email"
                name="email"
                v-validate="'required|email'"
                :class="{'heaven': isHeaven}"
              ></b-form-input>
              <b-form-invalid-feedback :state="!errors.has('email')">
                {{ $t('views.seller.login.text_1558') }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group :label="$t('views.seller.login.text_1559')" label-for="password">
              <b-form-input
                id="password"
                placeholder="••••••••"
                v-model="password"
                type="password"
                name="password"
                v-validate="'required'"
                :class="{'heaven': isHeaven}"
              ></b-form-input>
              <span
                class="mostrar-senha"
                ref="mostrar_senha"
                @click="mostrarSenha"
                >{{ $t('views.seller.login.text_1560') }}</span
              >
              <b-form-invalid-feedback :state="!errors.has('password')">
                {{ $t('views.seller.login.text_1561') }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-invalid-feedback :state="success">
              {{ $t('views.seller.login.text_1562') }}
            </b-form-invalid-feedback>

            <b-row class="justify-content-end mt-3">
              <router-link
                :to="{ name: 'Seller-Password-Recovery' }"
                class="recupera-senha-text"
                >{{ $t('views.seller.login.text_1563') }}
              </router-link>
            </b-row>


            <b-row class="mt-4">
              <b-overlay
                :show="loading"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="btn-acoes"
                :class="{'justify-content-end': isHeaven}"
              >
                <router-link
                  v-if="!isHeaven"
                  :to="{
                    name: 'Register',
                    query: {
                      type: isClient ? 'client' : 'seller',
                      ...(this.$route.query.cashback ? { cashback: this.$route.query.cashback } : {})
                    }
                  }"
                  class="recupera-senha-text"
                  >{{ $t('views.seller.login.text_1564') }}</router-link
                >

                <Captcha ref="Captcha" @changeVerify="changeVerify">
                  <BaseButton
                    variant="primary"
                    ref="button"
                    :disabled="loading"
                    type="submit"
                    v-if="!loginClub && !loginEnvios"
                    class="btn-login"
                    :class="{'heaven': isHeaven}"
                    @click="onSubmit"
                  >
                    {{ $t('views.seller.login.text_1565') }}
                  </BaseButton>

                  <BaseButton
                    variant="primary"
                    v-else
                    ref="button"
                    :disabled="loading"
                    :class="{'heaven': isHeaven}"
                    class="btn-login"
                    @click="externalSubmit"
                  >
                    {{ $t('views.seller.login.text_1565') }}
                  </BaseButton>
                </Captcha>
              </b-overlay>
            </b-row>

            <b-row class="mt-5">
              <a
                class="links-help"
                :href="isHeaven
                  ? (
                    $i18n.locale === 'en'
                      ? 'https://heaven.me/heaven-privacy-policy/'
                      : $i18n.locale === 'es'
                        ? 'https://heaven.me/heaven-politica-de-privacidad/'
                        : 'https://heaven.me/heaven-politica-de-privacidade/'
                    )
                  : 'https://greenn.com.br/politica-de-privacidade-greenn/'
                "
                target="_blank"
              >
                {{ $t('views.seller.login.text_1566') }}
              </a>
            </b-row>
          </b-form>
        </div>
        <!-- Loading -->
        <div
          v-if="loading"
          class="py-4 d-flex justify-content-center align-items-center"
        >
          <b-spinner variant="dark" label="Spinning"></b-spinner>
        </div>
      </b-col>
    </b-row>
    <ModalHeaven :requestHeaven="requestHeaven" :userId="userId"/>
    <ModalGreenn :requestGreenn="requestGreenn" :userId="userId"/>
  </b-container>
</template>

<script>
import Cookies from "js-cookie"
import Captcha from '@/components/security/Captcha.vue'
import Locales from '@/components/Locales.vue'
import ModalHeaven from '@/components/login/ModalHeaven.vue'
import ModalGreenn from '@/components/login/ModalGreenn.vue'

import AuthServiceClub from '@/services/resources/AuthServiceClub'
import AuthServiceEnvios from '@/services/resources/AuthServiceEnvios'
const serviceClub = AuthServiceClub.build()
const serviceEnvios = AuthServiceEnvios.build()

export default {
  name: 'Login',
  components: {
    Captcha, Locales, ModalHeaven, ModalGreenn
  },
  data() {
    return {
      email: "",
      password: "",
      success: true,
      loading: false,
      loginClub: false,
      loginEnvios: false,
      urlClub: "",
      showPassword: false,
      hash: null,
      id_product: null,
      errorCaptch: false,
      captcha:false,
      manager: null,
      requestHeaven: 0,
      requestGreenn: 0,
      userId: 0,
      showAlertCashback: false
    };
  },
  computed: {
    isClient() {
      return this.$store.getters["getIsClient"]
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    },
    isWebView() {
      return window.isWebView == true;
    }
  },
  methods: {
    changeVerify(e) {
      this.captcha = e;
      if (this.loginClub || this.loginEnvios) {
        this.externalSubmit("get");
        return;
      }
      this.onSubmit("get");
    },
    mostrarSenha() {
      const input = document.querySelector("#password");
      this.showPassword = !this.showPassword;
      if (this.showPassword) {
        input.setAttribute("type", "text");
        this.$refs.mostrar_senha.innerText = this.$t('views.seller.login.text_1567');
      } else {
        input.setAttribute("type", "password");
        this.$refs.mostrar_senha.innerText = this.$t('views.seller.login.text_1560');
      }
    },
    existClubParams() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const type = urlParams.get("type");
      const url = urlParams.get("redirectTo");
      if (type === undefined || type === "") {
        this.loginClub = false;
        this.loginEnvios = false;
      } else if (type === "club") {
        this.loginClub = true;
        if (url !== undefined && url !== "") {
          this.urlClub = url;
        }
      }else if (type === "envios") {
        this.loginEnvios = true;
      }
    },
    externalSubmit(get) {
      if (get !== "get") {
        this.$refs.Captcha.setCaptcha();
        return;
      }
      this.submit = true;
      this.success = true;

      var service = '';
      if(this.loginClub){
        service = serviceClub
      }else{
        service = serviceEnvios
      }

      service
        .login(this.email, this.password, this.captcha)
        .then((resp) => {
          if (resp.string) {
            if(this.loginClub){
              if (
                this.urlClub !== "" &&
                this.urlClub !== null &&
                this.urlClub !== undefined
              ) {
                window.location.href =
                  "http://" +
                  this.urlClub +
                  "?rdr=" +
                  resp.string +
                  "&type=greenn";
              } else {
                window.location.href =
                  process.env.VUE_CLUB_API_HOST+"?rdr=" + resp.string + "&type=greenn";
              }
            }else if(this.loginEnvios){
              window.location.href = process.env.VUE_ENVIOS_API_HOST+"/login?rdr=" + resp.string + "&type=greenn";
            }
          } else {
            this.success = false;
            this.loading = false;
          }
        })
        .catch(() => {
          this.success = false;
          this.loading = false;
        });
    },
    onSubmit(get) {
      if (get !== "get") {
        this.$refs.Captcha.setCaptcha();
        return;
      }
      this.submit = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.success = true;
          this.loading = true;
          let isIntegration = this.$route.path.startsWith('/login/integration')  ? true : false
          let data = {
            username: this.email,
            password: this.password,
            captcha: this.captcha,
            type: this.isClient ? 'CLIENT' : 'SELLER',
            isIntegration: isIntegration,
            redirect_url: this.$route.query.redirect_uri ?? null,
            client_id: this.$route.query.client_id ?? null,
            hash: this.$route.query.hash ?? null,
          };

          this.$store
            .dispatch("loginRequest", data)
            .then((res) => {
              if(res.status === false) {
                if(this.isHeaven) {
                  // modal de solicitacao de acesso ao heaven
                  this.requestHeaven = res.request_heaven
                  this.userId = res.id
                  if(!this.isClient) this.$bvModal.show("request-heaven");
                } else {
                  // modal de solicitacao de acesso ao greenn
                  this.requestGreenn = res.request_greenn
                  this.userId = res.id
                  if(!this.isClient) this.$bvModal.show("request-greenn");
                }
              } else {
                
                if(this.isClient) {
                  this.$router.push(`/dashboard${window.location.search}`)
                } else {
                  if(this.hash) {
                    this.$router.push(`/recrutamento/${this.hash}/?p_id=${this.id_product}&manager=${this.manager}&confirmOpen=open`)
                  } else {
                    this.$router.push(`/dashboard${window.location.search}`)
                  }
                }
              }
            })
            .catch(err => {
              console.error(err);
              this.$refs.Captcha.resetCaptcha()
            })
            .finally(() => {
              this.success = false
              this.submit = false
              this.loading = false
            })
        }
      })
    },
    removeLogoutParams(){
      //Remove queryParms de logout
      if(this.$route.query.logoutCashback){
        const url = new URL(window.location.href);
        url.searchParams.delete('logoutCashback');
        window.history.replaceState({}, document.title, url);
      }  
    },
    onHidden() {
      // Return focus to the button once hidden
      this.$refs.button.focus();
    },
  },
  updated() {
    const crisp = document.querySelector(".crisp-client")
    if (crisp) {
      setTimeout(() => {
        crisp.style.display = "none"
      })
    }
  },
  mounted() {
    this.removeLogoutParams();
    this.showAlertCashback = this.$route.query.cashback ? true : false;

    this.existClubParams();

    if (typeof this.$route.query.hash_a != "undefined") {
      this.hash = this.$route.query.hash_a
    }
    if (typeof this.$route.query.p_id != "undefined") {
      this.id_product = this.$route.query.p_id
    }
    if (typeof this.$route.query.manager != "undefined") {
      this.manager = this.$route.query.manager
    }


    if (typeof this.$route.query.email != "undefined") {
      this.email = this.$route.query.email;
      const input = document.querySelector("#password");
      if (input) {
        input.focus();
      }
    }

    if (window.isWebView == true) {
      window.flutter_inappwebview.callHandler('logoutUserHandler', { logout: true });
    }
  },
  async created() {
    // Verificar o rdr na rota
    if(this.$route.query.rdr) {
      let res = await this.$store.dispatch('recoveryHash', {token: this.$route.query.rdr})
      let access_token = "Bearer " + res.token
      Cookies.set("access_token", access_token, { expires: 365 })
      this.$store.commit('authSuccess', access_token)
      this.$router.push({ path: '/dashboard', query: {
        type: this.isClient ? 'client' : 'seller'
      }})
    }
  }
};
</script>

<style lang="scss" scoped>
.b-alert-cashback{
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: .5rem ;
  background-color: var(--primary-50) !important;
  border-color: var(--old-500)  !important;
  color: var(--old-500)  !important;
}
.title-alert{
  margin-bottom: 1rem;
  font-weight: bold  !important;
  color: var(--old-500)  !important;
}
.text-alert{
  color: var(--old-800) !important;
}

.text-green {
  color: var(--old-500);
  font-weight: bold;
}

.bg-login {
  background: var(--old-500);
  overflow: hidden;
}
.form-group {
  position: relative;
}
.form-group + .form-group {
  margin-top: 20px;
}

.left-side {
  background-image: url("~@/assets/img/login-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.left-side.heaven {
  background: radial-gradient(circle, #0C1820 100%, var(--blue-500) 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  right: 0;
  top: 0;
  z-index: 800;
  width: 100%;
  height: 100vh;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.left-side.heaven::after {
  content: url("~@/assets/img/icons-heaven/logo-dark.svg");
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.right-side {
  background: var(--gray-white);
  padding: 20px 0px;
  height: 100%;

  .inner-wrapper {
    padding: 1% 18%;
    // width: 60%;

    .logo {
      img {
        width: 120px;
        height: auto;
      }
    }
  }
}
.recupera-senha-text {
  color: var(--gray-200);
  font-size: 14px;
}
.links-help {
  font-size: 12px;
  color: var(--gray-200);
  margin-top: 20px;
}
.btn-acoes {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mostrar-senha {
  font-size: 13px;
  position: absolute;
  top: 55%;
  right: 20px;
  cursor: pointer;
  color: var(--gray-200);
}
@media screen and (max-width: 768px) {
  .right-side .inner-wrapper {
    padding: 6% 11%;
  }
  .grecaptcha-badge {
    right: -194px !important;
  }
  .logo img {
    width: 80px !important;
    padding-top: 7px;
  }
  .links-help {
    margin: 0;
  }
}
.form-control.heaven:hover {
  border-color: var(--blue-500) !important;
}
.register-title {
  margin: var(--spacing-7-5) var(--spacing-0) var(--spacing-6) var(--spacing-0);
  display: grid;
  gap: var(--spacing-8);
}
</style>
